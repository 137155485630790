<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'Go to backoffice': 'Ir para o escritório',
      'Operations': 'Operações',
      'Buy': 'Comprar',
      'Wallet': 'Carteiras',
      'Orders': 'Ordens',
      'Bank Deposits': 'Depósitos',
      'Bank Withdrawals': 'Saques',
      'Crypto Deposits': 'Entrada de moedas',
      'Crypto Withdrawals': 'Saída de moedas',
      'Statement': 'Extrato',
      'Available': 'Disponível',
      'In use': 'Em uso',
    },
    es: {
      'Go to backoffice': 'Ir para o escritório',
      'Operations': 'Operações',
      'Buy': 'Comprar',
      'Wallet': 'Carteiras',
      'Orders': 'Ordens',
      'Bank Deposits': 'Banco',
      'Bank Withdrawals': 'Saques',
      'Crypto Deposits': 'Entrada de moedas',
      'Crypto Withdrawals': 'Saída de moedas',
      'Statement': 'Extrato',
      'Available': 'Disponível',
      'In use': 'Em uso',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="pt-1">
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t('Go to backoffice') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Exchange') }}</li>
      <li>
        <router-link tag="a" to="/exchange/market" class="side-nav-link-ref">
          <i class="bx bx-sort"></i>
          <span>{{ t('Buy') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/exchange/wallets" class="side-nav-link-ref">
          <i class="bx bx-wallet-alt"></i>
          <span>{{ t('Wallet') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/exchange/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ul"></i>
          <span>{{ t('Orders') }}</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link tag="a" to="/exchange/bank/deposits" class="side-nav-link-ref">
          <i class="bx bxs-bank"></i>
          <span>{{ t('Bank Deposits') }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link tag="a" to="/exchange/bank/withdrawals" class="side-nav-link-ref">
          <i class="bx bxs-dollar-circle"></i>
          <span>{{ t('Bank Withdrawals') }}</span>
        </router-link>
      </li> -->
      <li>
        <router-link tag="a" to="/exchange/crypto/deposits" class="side-nav-link-ref">
          <i class="bx bx-transfer-alt"></i>
          <span>{{ t('Crypto Deposits') }}</span>
        </router-link>
      </li>
       <li>
        <router-link tag="a" to="/exchange/crypto/withdrawals" class="side-nav-link-ref">
          <i class="bx bx-transfer-alt"></i>
          <span>{{ t('Crypto Withdrawals') }}</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link tag="a" to="/exchange/statement" class="side-nav-link-ref">
          <i class="bx bx-list-check"></i>
          <span>{{ t('Statement') }}</span>
        </router-link>
      </li> -->
      <li class="menu-title"></li>
      <li class="d-none menu-balance">
        <div class="mx-4">
          <div class="d-flex justify-content-between mb-3">
            <strong class="text-uppercase">{{ t('Available') }}</strong>
            <!-- <strong class="text-uppercase">{{ t('In use') }}</strong> -->
          </div>
          <div class="mb-3">
            <strong>XFB</strong>
            <div class="d-flex justify-content-between">
              <span>0,00000000</span>
              <!-- <span>0,00000000</span> -->
            </div>
          </div>
          <div class="mb-3">
            <strong>USD</strong>
            <div class="d-flex justify-content-between">
              <span>US$ 0,00</span>
              <!-- <span>US$ 0.00</span> -->
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Wallets': 'Carteiras',
      'Copy': 'Copiar',
      'Copy address': 'Copiar endereço',
      'Close': 'Sair',
      'Currency': 'Moeda',
      'Balance': 'Saldo',
      'Balance in use': 'Saldo em uso',
      'Estimated value': 'Valor estimado',
      'Address': 'Endereço',
      'Estimated total': 'Total estimado',
      'Increase balance': 'Aumentar o saldo',
      'Deposit': 'Depositar',
      'Wallet address': 'Endereço da carteira',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Wallets': 'Carteiras',
      'Copy': 'Copiar',
      'Copy address': 'Copiar endereço',
      'Close': 'Sair',
      'Currency': 'Moeda',
      'Balance': 'Saldo',
      'Balance in use': 'Saldo em uso',
      'Estimated value': 'Valor estimado',
      'Address': 'Endereço',
      'Estimated total': 'Total estimado',
      'Increase balance': 'Aumentar o saldo',
      'Deposit': 'Depositar',
      'Wallet address': 'Endereço da carteira',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      loading: true,
      errored: false,

      table: {
        heade: [
          'Currency', 'Balance', 'Balance in use', 'Estimated value', 'Address', ''
        ],
        loading: false,
        errored: false,
        empty: null,
      },

      modal: {
        status: false,
        currency: null,
        address: null,
      },

      fiat: {
        currency: '-',
        balance: '0.00',
        balance_in_use: '0.00',
      },

      crypto: {
        estimated_total: '0.00',
        list: null
      }
    };
  },
  methods: {
    getWallets: function () {
      api
      .get('exchange/wallets')
      .then((response) => {
        if (response.data.status === 'success') {
          this.fiat.currency = response.data.fiat.currency
          this.fiat.balance = response.data.fiat.balance
          this.fiat.balance_in_use = response.data.fiat.balance_in_use
          this.crypto.estimated_total = response.data.crypto.estimated_total
          this.crypto.list = response.data.crypto.list
          this.loading = false
        } else {
          this.loading = true
        }
      })
      .catch(error => {
        this.errored = error
      })
    },
    getQrcode: function (crypto) {
      this.modal.status = true
      this.modal.currency = crypto.currency
      this.modal.address = crypto.address
    }
  },
  mounted() {
    this.getWallets()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Wallets') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>{{ t('Currency') }}</th>
                    <th>{{ t('Balance') }}</th>
                    <th>{{ t('Balance in use') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ fiat.currency }}</td>
                    <td>{{ fiat.balance | currency }}</td>
                    <td>{{ fiat.balance_in_use | currency }}</td>
                    <td class="text-right">
                      <!-- <div class="d-flex align-items-center justify-content-end">
                        <span class="m-0 pr-2">{{ t('Increase balance') }}:</span>
                        <button class="btn btn-dark btn-sm">{{ t('Deposit') }}</button>
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(crypto,index) in crypto.list" :key="index">
                    <td>{{ crypto.currency }}</td>
                    <td>{{ crypto.balance }}</td>
                    <td>{{ crypto.balance_in_use }}</td>
                    <td>{{ crypto.estimated_value | currency }}</td>
                    <td class="text-primary">{{ crypto.address }}</td>
                    <td class="text-right">
                      <button class="btn btn-primary btn-sm" v-clipboard:copy="crypto.address">{{ t('Copy') }}</button>
                      <!-- <button class="btn btn-primary btn-sm ml-2" @click="getQrcode(crypto)">
                        <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" class="c0163"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z" fill="#D8D8D8" opacity=".052"></path><path d="M3.5 3.5h5v5h-5z" stroke="currentcolor"></path><path d="M5 5h2v2H5z" fill="currentcolor"></path><path d="M3.5 11.5h5v5h-5z" stroke="currentcolor"></path><path d="M5 13h2v2H5z" fill="currentcolor"></path><path d="M13 12h3v1h-3v1h-1v-2h1zm2 4v-1h1v2h-4v-1h3zm1-3h1v1h-1v-1z" fill="currentcolor"></path><rect height="19" width="19" rx="1" stroke="currentcolor" x=".5" y=".5"></rect><g><path d="M11.5 3.5h5v5h-5z" stroke="currentcolor"></path><path d="M13 5h2v2h-2z" fill="currentcolor"></path></g></g></svg>
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between mt-3 p-1 border-top border-bottom">
              <div>{{ t('Estimated total') }}:</div>
              <div class="p-3 pr-3 bg-gray text-center bg-soft-dark" style="min-width: 227px;">US$ 0,00</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.status" :title="t(modal.currency)" centered>
      <p class="m-0">{{ t('Wallet address') }}</p>
      <p class="m-0 text-primary">{{ modal.address }}</p>
      <div class="d-flex align-items-center justify-content-around border mt-3">
        <img class="p-3 w-50" :src="'https://chart.googleapis.com/chart?cht=qr&chs=230x230&chl=bitcoin:'+ modal.address">
        <p class="p-3 m-0 w-50">
          <span>Você pode copiar o endereço acima ou utilizar um leitor de QR Code para obter o endereço desta carteira com mais comodidade.</span>
        </p>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <button class="btn btn-secondary py-3 px-4" @click="modal.status=false">{{ t('Close') }}</button>
          <button class="btn btn-success py-3 px-4" v-clipboard:copy="modal.address">{{ t('Copy address') }}</button>
        </div>
      </template>
    </b-modal>
  </Layout>
</template>